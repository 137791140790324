<template>
  <div id="lunwenUpload">
    <div class="right-box">
      <el-form :model="papers" label-position="left" :rules="rules" ref="ruleForm" label-width="100px"
        class="demo-ruleForm">
        <div class="tabs">
          <span class="toLeft" @click="toLeft">
            < </span>
              <ul ref="dispkat" class="dispkat">
                <!-- <li>
              <img src="@/assets/01.png" width="210" height="97" alt="">
              <div class="select"><img src="@/assets/select.png" alt=""> </div>
            </li>
            <li>
              <img src="@/assets/02.png" width="210" height="97" alt="">
              <div class="select"><img src="@/assets/select.png" alt=""> </div>
            </li>
            <li>
              <img src="@/assets/03.png" width="210" height="97" alt="">
              <div class="select"><img src="@/assets/select.png" alt=""> </div>
            </li>
            <li>
              <img src="@/assets/04.png" width="210" height="97" alt="">
              <div class="select"><img src="@/assets/select.png" alt=""> </div>
            </li>
            <li>
              <img src="@/assets/01.png" width="210" height="97" alt="">
              <div class="select"><img src="@/assets/select.png" alt=""> </div>
            </li>
            <li>
              <img src="@/assets/02.png" width="210" height="97" alt="">
              <div class="select"><img src="@/assets/select.png" alt=""> </div>
            </li> -->
                <li v-for="item, index in dataList" :key="index" @click="selectOne(index)">
                  <img :src="item.img" width="210" height="97" alt="">
                  <div class="select" :class="{ active: item.active }"><img src="@/assets/select.png" alt=""> </div>
                  <div class="shadow" :class="{ active: item.active }"></div>
                </li>
              </ul>
              <span class="toRight" @click="toRight"> > </span>
        </div>
        <img class="liucheng" width="900" height="60" src="@/assets/lc1.png">
        <div v-show="show" class="modal">
          <div class="top">
            <img :src="showDataList[selectIndex].img" width="210" alt="">
            <div>
              <p>版本类型：<span style="color:rgb(21,123,222);font-weight: bold;">{{ showDataList[selectIndex].versionType
              }}</span></p>
              <p>适用类型：<span>{{ showDataList[selectIndex].equalType }}</span></p>
              <p>检测语种：<span>{{ showDataList[selectIndex].lang }}</span></p>
            </div>
          </div>
          <div class="bottom">
            <div style="color: #999;font-size: 14px;text-align: left;margin-top: 30px;">检测库范围：</div>
            <ul>
              <li v-for="item in showDataList[selectIndex].area" :key="item.id">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <div class="info" v-show="selectIndex === 0">
          <div><span>超星大雅检测全库版：</span> 适用课程、期末、图书、期刊、毕论（设计）等等。</div>
          <div style="cursor: pointer;" @mouseenter="showModal" @mouseleave="show = false"><img
              src="@/assets/question.png" width="14" alt=""> 比对库范围</div>
        </div>
        <div class="info" v-show="selectIndex === 1">
          <div><span>维普论文检测大学生版：</span> 适用大中专、本科阶段的毕业生。</div>
          <div style="cursor: pointer;" @mouseenter="showModal" @mouseleave="show = false"><img
              src="@/assets/question.png" width="14" alt=""> 比对库范围</div>
        </div>
        <div class="info" v-show="selectIndex === 2">
          <div><span>维普论文检测研究生版：</span> 适用硕士/博士论文。</div>
          <div style="cursor: pointer;" @mouseenter="showModal" @mouseleave="show = false"><img
              src="@/assets/question.png" width="14" alt=""> 比对库范围</div>
        </div>
        <div class="info editVer" v-show="selectIndex === 3">
          <div><span>格子达论文查重检测：</span>
            <div>适用大中专、本科硕博的毕业生论文查重检测。</div>
            <div>检测时间为5-30分钟，若遇高峰期则120分钟内出结果！！</div>
          </div>
          <div style="cursor: pointer;" @mouseenter="showModal" @mouseleave="show = false"><img
              src="@/assets/question.png" width="14" alt=""> 比对库范围</div>
        </div>
        <div class="info" v-show="selectIndex === 4">
          <div><span>维普论文检测职称版：</span> 适用职称评审论文、已发表论文</div>
          <div style="cursor: pointer;" @mouseenter="showModal" @mouseleave="show = false"><img
              src="@/assets/question.png" width="14" alt=""> 比对库范围</div>
        </div>
        <div class="info" v-show="selectIndex === 5">
          <div><span>维普论文检测编辑部版：</span> 1、适用于期刊、图书、会议论文、科技成果等领域，提供大数据的出版辅助审查。</div>
          <div style="cursor: pointer;" @mouseenter="showModal" @mouseleave="show = false"><img
              src="@/assets/question.png" width="14" alt=""> 比对库范围</div>
        </div>
        <div class="info" v-show="selectIndex === 6">
          <div><span>维普论文检测研究生版：</span> 适用硕士/博士论文。</div>
          <div style="cursor: pointer;" @mouseenter="showModal" @mouseleave="show = false"><img
              src="@/assets/question.png" width="14" alt=""> 比对库范围</div>
        </div>
        
        <h3 class="upload">上传论文：</h3>
        <!-- <el-form-item prop="value">
          <span slot="label">
            <i class="el-icon-view"></i>
            版本选择</span>
          <el-select v-model="papers.value" placeholder="请选择商品">
            <el-option v-for="com in commodity" :key="com.id" :label="com.names" :value="com.id">
            </el-option>
          </el-select>
          <span class="tips">[必选]</span>
        </el-form-item> -->
        <el-form-item prop="title">
          <span slot="label">
            <i class="el-icon-link"></i>
            论文标题</span>
          <el-input v-model.trim="papers.title" maxlength="100" placeholder="请输入您的论文题目，最长不超过100字，此项将显示在报告中，填错不影响结果"></el-input>
          <div class="showNum"><span>{{ papers.title ? papers.title.length : 0 }}</span>/<span>100</span></div>
        </el-form-item>

        <el-form-item prop="author">
        <span slot="label">
            <i class="el-icon-user"></i>
            论文作者
        </span>
        <el-input
            v-model.trim="papers.author"
            maxlength="50"
            placeholder="请输入论文作者名字，最长不超过50字，此项将显示在报告中，填错不影响结果"
        ></el-input>
        <div class="showNum">
            <span>{{ papers.author ? papers.author.length : 0 }}</span>/<span>50</span>
        </div>
    </el-form-item>
    <el-form-item prop="carMy">
        <span slot="label">
            <i class="el-icon-search"></i>
            检测卡号
        </span>
        <el-input
            v-model.trim="papers.carMy"
            maxlength="100"
            placeholder="请输入或者复制粘贴购买的检测卡号"
        ></el-input>
        <div class="showNum">
            <span>{{ papers.card ? papers.card.length : 0 }}</span>/<span>100</span>
        </div>
    </el-form-item>


        <!--      <el-form-item label="手机号" prop="phone" >-->
        <!--        <el-input v-model="papers.phone"></el-input>-->
        <!--      </el-form-item>-->

        <el-form-item prop="address" class="up_box">
          <span slot="label">
            <i class="el-icon-upload"></i>
            上传文件</span>
          <el-upload ref="upload" drag :multiple="false" class="upload-demo" action="/api/file/upload" :limit="1"
            :on-error="errorFile" :before-upload="checkFile" :on-remove="removeFile" :on-success="successSave">
            <!-- <i class="el-icon-upload"></i> -->
            <p class="el-upload__text">
              <!-- 将文件拖到此处，或<em>点击上传</em><br> -->
            <div>
              <el-button type="primary" round><span
                  style="font-size: 20px;vertical-align: -2px;height: 20px;">+</span>&nbsp;&nbsp; 选择文件
              </el-button>
            </div>
            <!-- <div v-show="!uploadSuc"> -->
            <p class="uphandle">点击上传或拖动文件到框内上传</p>
            <p class="uptips">支持文档格式：word文件(.doc .docx .pdf) 、文本文件(.txt)，文件大小在30M内</p>
            <p class="upred">注：请确保上传的文档一定是检测的文档；修订、批注模式会导致报告内容缺失。请核查论文内容后上传 </p>
            <p class="upred">注：PDF容易导致检测报告乱码，解析结果慢不建议上传。 </p>

            <!-- </div> -->
            <!-- <div v-show="uploadSuc">
              <p class="uphandle">{{ this.papers.address }}</p>
              <div>
              <el-button round><span
                  style="font-size: 20px;vertical-align: -2px;height: 20px;">+</span>&nbsp;&nbsp; 更换文档
              </el-button> 
            </div>
            </div>-->
            </p>
          </el-upload>
          <!-- <div class="del">
            <el-button size="small" type="danger" @click="deleteFile">删除重选
            </el-button>
          </div> -->
        </el-form-item>
        <el-form-item style="display: flex;justify-content: left;">
          <el-button type="danger" @click="submitForm('ruleForm')"><span
                  style="font-size: 20px;vertical-align: -2px;height: 20px;">提交检测</span>
          </el-button>
          <!-- <el-button round @click="resetForm('ruleForm')">重置信息</el-button> -->
        </el-form-item>
      </el-form>
      <h4 class="notice-title fw">
        <img src="@/assets/help.png" alt="">
        检测须知
      </h4>
      <ul class="rightFooter">
        <li>
          <span class="label label-danger">正版验证</span>官网正版检测系统，谨防假冒
          
        </li>
        <li>
          <span class="label label-warning">重要提醒</span>系统支持中文、英文、小语种论文检测，全程封闭式加密传输/检测所提交的论文，检测完成后可自行删除，安全放心检测。
        </li>
        <li>
          <span class="label label-warning">文档格式</span>建议上传word文件(.doc .docx) 文本文件(.txt) 检测,PDF容易检测乱码不建议上传。
        </li>
        <li>
          <span class="label label-primary">检测部分</span>检测部分是目录/摘要/正文/参考文献等，可以删除其他不检测的内容。
        </li>
        
        <li>
          <span class="label label-primary">关于退款</span>此系统一旦提交，开始检测后，就会产生成本，我们不接受退款，多谢理解！
        </li>
      </ul>
      <!-- <h4 class="notice-title fw">
        <img src="@/assets/help.png" alt="">
        报告样例
      </h4>
      <div class="rightFooter">
        <p>
          <img style="width: 720px" src="../../assets/001.png" alt="报告样例" />
        </p>
      </div> -->
    </div>
    <div class="left-box">
      <!-- <div class="download" @click="dom_a">
        <span>点此获取检测结果</span>
      </div>
      <h3 class="fw">
        <img src="@/assets/fanwei.png" alt="">
        &nbsp; 适用范围
      </h3>
      <div class="box-hr"></div>
      <span class="left-box-a">专科、本科硕博毕业论文<br />课程、期刊、毕业设计、学位论文<br />论文检测系统</span>

      <h3>检测语种</h3>
      <span style="color: red; font-weight: bold" class="left-box-a">中文、英文、小语种</span>
      <h3>检测用时</h3>
      <span style="color: red; font-weight: bold"
        class="left-box-a">正常1-30分钟出结果，高峰期1-5小时（夜间凌晨24:00后提交的，一般是上午10点左右出结果）</span>
      <h3>检测价格</h3>
      <span style="color: red; font-weight: bold" class="left-box-a ng-binding">
        商品标价
      </span>
      <br />
      <div class="box-hr"></div> -->
      
      <h3 class="fw" style="margin-top: 40px;position: relative;">
        <img src="@/assets/exc.png" alt="" style="background-color: #0c368a;border-radius: 50%;">
        &nbsp; 论文客服咨询
        <!-- <div class="ercode" v-show="ercode">
          <div style="display: flex;justify-content: space-around;">
            <img src="@/assets/erer.png" width="300" alt="">
            <span style="line-height: 50px;cursor: pointer;" @click="ercode = false">X</span>
          </div>
          <div style="text-align: center;margin-top: 20px;">
            <img src="@/assets/ercode.png" width="288" alt="">
          </div>
        </div> -->
      </h3>
      <el-divider></el-divider>
      <div class="excuse" @mouseenter="ercodeHandle"><img src="@/assets/wechat.png" style="margin-right: 16px;" width="24"
          alt="">论文客服</div>
      <h3 class="fw">
        &nbsp;
      </h3>
      <h3 class="fw" style="margin-top: -20px;">
        <img src="@/assets/help.png" alt="">
        &nbsp; 温馨提示
      </h3>
      <el-divider></el-divider>
      <div class="disInfo">
        <p class="title">1.如何统计论文字符数</p>
        <p class="content">打开文档，点击左上角的 审阅 功能 然后点击左上角 字数统计，查看 字符数。不满1千字符算1千字符，例如5001-5999 都算6千字符；12001-12999 都算13千字符。
        </p>
      </div>
      <div class="disInfo">
        <p class="title">2.检测报告</p>
        <p class="content">PDF报告、整体报告、比对报告---多版本报告以及总相似比、引用率、重复率、自写率---多指标提示</p>
      </div>
    </div>
  </div>
</template>

<script>
import test from "@/views/lunwen/test";

export default {
  name: "lunwenUpload",
  data() {
    let reg =
      /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
    //验证方法
    let validatorPhone = function (phone, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!reg.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };

    let validatorCarMy = function (carMy, value, callback) {
      if (value == "") {
        callback(new Error("检测卡号不能为空"));
      }
    };

    let validatorValue = function (values, value, callback) {
      if (value == 0) {
        callback(new Error("请选择需要检测的版本"));
      }
    };
    return {
      times: "",
      resSuccess: "",
      count: 9,
      showCount: 4,
      initCount: 4,
      papers: {
        id: 0,
        title: "",
        author: "",
        carMy: "",
        phone: "",
        address: "",
        value: 0,
        number: 0,
      },
      selectIndex: 3,
      countZS: "",
      papersBo: "",
      commodity: [],
      rules: {
        title: [
          { required: true, message: "请输入标题名称", trigger: "blur" },
          { min: 1, max: 100, message: "1到100个字", trigger: "blur" },
        ],
        author: [
          { required: true, message: "请输入作者名称", trigger: "blur" },
          { min: 1, max: 50, message: "1到50个字", trigger: "blur" },
        ],
        card: [
          { required: true, message: "请输入卡号", trigger: "blur" },
          { min: 1, max: 50, message: "1到100个字", trigger: "blur" },
        ],
        phone: [{ validator: validatorPhone, trigger: "blur" }],
        carMy: [
          { message: "检测卡号不能为空", trigger: "blur" },
        ],
        address: [{ required: true, message: "文件不能为空", trigger: "blur" }],
      },
      dataList: [
        {
          id: 1,
          img: require('@/assets/01.png'),
          active: true
        },
        {
          id: 2,
          img: require('@/assets/02.png'),
          active: false
        },
        {
          id: 3,
          img: require('@/assets/03.png'),
          active: false
        },
        {
          id: 4,
          img: require('@/assets/09.png'),
          active: false
        },
        {
          id: 5,
          img: require('@/assets/05.png'),
          active: false
        },
        {
          id: 6,
          img: require('@/assets/04.png'),
          active: false
        },
      ],
      show: false,
      ercode: false,
      uploadSuc: false,
      showDataList: [
{
          id: 1,
          img: require('@/assets/01.png'),
          versionType: '大雅全文库',
          equalType: '适用课程/期末/期刊/职称/本科专科博硕士论文检测',
          lang: '中文、英文、繁体、小语种',
          area: ['图书450万种', '期刊3万多种（7300多万篇）', '学位论文450多万篇', '会议论文400多万篇', '报纸1800多种（2.1亿多篇）',
            '还有网页、文档、外文等其他海量资源']
        },
        {
          id: 2,
          img: require('@/assets/02.png'),
          versionType: '维普大学生版',
          equalType: '适用大中专、本科阶段的毕业生',
          lang: '中文、英文、繁体、小语种',
          area: ['中文科技期刊论文全文数据库', '中文主要报纸全文数据库', '中国专利特色数据库', '博士/硕士学位论文全文数据库', '中国主要会议论文特色数据库',
            '港澳台文献资源', '外文特色文献数据全库', '维普优先出版论文全文数据库', '互联网数据资源/互联网文档资源', '图书资源', '古籍文献资源', '年鉴资源', 'IPUB原创作品']
        },
        {
          id: 3,
          img: require('@/assets/03.png'),
          versionType: '维普研究生版',
          equalType: '适用硕士、博士论文',
          lang: '中文、英文、繁体、小语种',
          area: ['中文科技期刊论文全文数据库', '中文主要报纸全文数据库', '中国专利特色数据库', '博士/硕士学位论文全文数据库', '中国主要会议论文特色数据库',
            '港澳台文献资源', '外文特色文献数据全库', '维普优先出版论文全文数据库', '互联网数据资源/互联网文档资源', '图书资源', '古籍文献资源', '年鉴资源', 'IPUB原创作品']
        },
        {
          id: 4,
          img: require('@/assets/09.png'),
          versionType: '格子达查重',
          equalType: '适用论文检测',
          lang: '中文、英文、繁体、小语种',
          area: ['中国学术期刊网络出版总库', '中国博士学位论文全文数据库', '中国优秀硕士学位论文全文数据库', '中国重要会议论文数据库', '中国重要报纸全文数据库',
            '中国专利全文数据库', '互联网数据资源', '英文数据库(涵盖期刊、博硕、会议的英文数据以及德国Springer、英国Taylor&Francis期刊数据库', '港澳台学术文献库', '优先出版文献库', '互联网文档资源', '图书资源', '大学生论文联合比对库', '个人对比库']
        },
        {
          id: 5,
          img: require('@/assets/05.png'),
          versionType: '职称版',
          equalType: '适用职称评审论文、已发表论文',
          lang: '中文、英文、繁体、小语种',
          area: ['中文科技期刊论文全文数据库', '中文主要报纸全文数据库', '中国专利特色数据库', '博士/硕士学位论文全文数据库', '中国主要会议论文特色数据库',
            '港澳台文献资源', '外文特色文献数据全库', '维普优先出版论文全文数据库', '互联网数据资源/互联网文档资源', '图书资源', '古籍文献资源', '年鉴资源', 'IPUB原创作品']
        },
        {
          id: 6,
          img: require('@/assets/06.png'),
          versionType: '万方检测通用版',
          equalType: '适用大中专、本科阶段的毕业生',
          lang: '中文、英文、繁体、小语种',
          area: ['中文科技期刊论文全文数据库', '中文主要报纸全文数据库', '中国专利特色数据库', '博士/硕士学位论文全文数据库', '中国主要会议论文特色数据库',
            '港澳台文献资源', '外文特色文献数据全库', '维普优先出版论文全文数据库', '互联网数据资源/互联网文档资源', '图书资源', '古籍文献资源', '年鉴资源', 'IPUB原创作品']
        },
        {
          id: 7,
          img: require('@/assets/09.png'),
          versionType: '维普编辑部版',
          equalType: '适用期刊投稿、会议论文、报告等',
          lang: '中文、英文、繁体、小语种',
          area: ['中文科技期刊论文全文数据库', '中文主要报纸全文数据库', '中国专利特色数据库', '博士/硕士学位论文全文数据库', '中国主要会议论文特色数据库',
            '港澳台文献资源', '外文特色文献数据全库', '维普优先出版论文全文数据库', '互联网数据资源/互联网文档资源', '图书资源', '古籍文献资源', '年鉴资源', 'IPUB原创作品']
        },
        {
          id: 8,
          img: require('@/assets/08.png'),
          versionType: '知网检测本科版',
          equalType: '适用大中专、本科阶段的毕业生',
          lang: '中文、英文、繁体、小语种',
          area: ['中国学术期刊网络出版总库', '中国博士学位论文全文数据库', '中国优秀硕士学位论文全文数据库', '中国重要会议论文数据库', '中国重要报纸全文数据库',
            '中国专利全文数据库', '互联网数据资源', '英文数据库(涵盖期刊、博硕、会议的英文数据以及德国Springer、英国Taylor&Francis期刊数据库', '港澳台学术文献库', '优先出版文献库', '互联网文档资源', '图书资源', '大学生论文联合比对库', '个人对比库']
        },
      ]
    };
  },
  methods: {
    showModal(i) {
      this.show = true
    },
    ercodeHandle() {
      this.ercode = true
    },
    selectOne(i) {
      this.selectIndex = i
      this.dataList.filter(v => v.active = false)
      this.dataList.map((f, index) => {
        if (i === index) {
          f.active = true
        }
      })
    },
    timings() {
  // 使用 setTimeout 确保只弹出一次消息
  this.times = setTimeout(() => {
    this.$message.warning("文件上传中。。。文件越大，上传越慢 若上传超过3分钟可以联系客服！！");
  }, 300);
},

    toRight() {
      if (this.count > 4 && this.showCount != this.count) {
        this.showCount++
        this.$refs.dispkat.style.transform = `translateX(-${230 * (this.showCount - this.initCount)}px)`
      }
    },
    toLeft() {
      if (this.showCount > 4) {
        this.showCount--
        this.$refs.dispkat.style.transform = `translateX(${-230 * (this.showCount - this.initCount)}px)`
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.selectIndex === '') {
            this.$message({ message: "未选择版本，请选择检测版本", type: "warning" });
            return;
          }
          this.papers.value = this.dataList.filter((v, i) => i == this.selectIndex)[0].id
          this.axios
            .get(
              "/card/find/byCarMy/" +
              this.papers.carMy +
              "/" +
              this.papers.value
            )
            .then((res) => {
              if (res.data != "ok") {
                this.$message({ message: res.data, type: "error" });
                return;
              } else {

                this.axios
                  .post("/papers/addPapers", this.papers)
                  .then((res) => {
                    if (res.data == "ok") {
                      this.$message({ message: "上传成功", type: "success" });
                      console.log(this.papers);
                      this.$router.push({ path: "/report?query=" + this.papers.carMy });
                      this.papersBo = "";
                      this.$refs["ruleForm"].resetFields();
                      this.$refs.upload.clearFiles();
                      return;
                    }
                    this.$message({ message: "上传失败，请刷新网页重新上传或者咨询客服原因", type: "error" });
                  });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      let file = { response: this.papersBo };
      this.removeFile(file, null);
    },
    checkFile(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ["docx", "doc", "txt", "pdf", "zip"];

      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          message: "上传文件只能是doc/docx/txt/pdf/zip格式",
          type: "warning",
        });
        return false;
      }
      this.timings();
    },
    successSave(response, file, fileList) {
      this.papersBo = response;
      if (response.count == 0) {
        this.countZS = ",不支持字数显示";
      } else {
        this.countZS = ",文件字符数为:" + response.count;
      }
      this.papers.number = response.count;
      this.papers.address = response.fileName;
      clearInterval(this.times);
      this.$message.success("文件上传成功");
      this.uploadSuc = true
    },
    removeFile(file, fileList) {
      if (file.response == "") {
        return;
      }
      let aaa = file.response.fileName;
      let ccc = aaa.substring(
        aaa.indexOf("2022-check-mulu") + "2022-check-mulu".length + 1,
        aaa.indexOf("?")
      );
      this.axios.get("/file/remove/" + ccc).then((res) => {
        console.log(res);
        this.papers.address = "";
        this.$refs.upload.clearFiles();
        this.countZS = "";
        this.$message({ message: "删除成功", type: "warning" });
      });
    },
    deleteFile() {
      let file = { response: this.papersBo };
      this.removeFile(file, null);
    },
    errorFile(err, file, fileList) {
      this.papers.address = "";
      this.$message({ message: "上传失败,请刷新网页重新上传或者咨询客服原因", type: "error" });
    },
    calculate(newDate, oldDate) {
      var s1 = oldDate.getTime(),
        s2 = newDate.getTime();

      var total = (s2 - s1) / 1000;
      console.log(total);
      var min = parseInt(total / 60); //计算整数分
      return min;
    },
    resS() {
      let item = localStorage.getItem("login");
      if (item != null) {
        this.resSuccess = 0;
      }
      if (this.resSuccess == 0) {
        let kami = document.getElementById("kami");
        kami.style.display = "block";
        localStorage.setItem("login", this.resSuccess);
        return;
      }
    },
    selectComm() {
      this.axios.get("/commodity/find").then((res) => {
        this.commodity = res.data;
      });
    },
    dom_a() {
      this.$router.push({ path: "/report" });
    },
    backtop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
  },
  watch: {
    dataList() {
      if (this.dataList) {
        let param = this.$route.query.id
        console.log('param01: ', param);
        if (param !== '' && param !== null && param !== 'undefined') {
          this.selectIndex = param.substring(param.length - 1) - 1
        }
      }
    }
  },
  mounted() {
    let param = this.$route.query.id
    if (param) {
      let i = param.substring(param.length - 1) - 1
      this.dataList.filter(v => v.active = false)
      this.dataList.map((f, index) => {
        if (i === index) {
          f.active = true
        }
      })
      this.backtop()
      this.selectIndex = i
      console.log('param02: ', i);
    }
    this.resS();
    // this.selectComm();
  },
  created() {
    this.resSuccess = this.$route.query.success;
    let param = this.$route.query.id
    this.axios.get('/commodity/find').then(res => {
      this.dataList = res.data;
      if (param) {
        let index = param.substring(param.length - 1) - 1
        this.dataList.map((item, i) => {
          if (i === index) {
            item.active = true
          } else {
            item.active = false
          }
          item.img = require(`@/assets/0${i + 1}.png`)
        })
      } else {
        this.dataList.map((item, i) => {
          if (i == 0) {
            item.active = true
          } else {
            item.active = false
          }
          item.img = require(`@/assets/0${i + 1}.png`)
        })
      }
      console.log(res.data)
    })
  },
};    
</script>

<style scoped>
#lunwenUpload {
  display: flex;
  position: relative;
  left: -60px;
}

.left-box {
  flex: 1;
  text-align: left;
  min-width: 240px;
}

.right-box {
  position: relative;
  width: 900px;
  padding: 0 10%;
  padding-left: 0;
}

.rightFooter {
  width: 100%;
  padding: 28px 0px 35px 0px;
}

.rightFooter li {
  width: 96%;
  position: relative;
  color: #878787;
  padding-left: 100px;
  line-height: 22px;
  font-size: 15px;
  margin-bottom: 10px;
}

.rightFooter span {
  display: inline-block;
  height: 22px;
  text-align: center;
  line-height: 22px;
  color: #fff;
  font-size: 14px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 0px 13px;
  position: absolute;
  left: 0;
}

.box-hr {
  border-top: 1px solid #1b7a02;
  border-bottom: 2px solid #1b7a02;
  height: 5px;
  margin: 10px 0;
  width: 100%;
}

.left-box-a {
  display: block;
  line-height: 26px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #666;
}

.left-box-a i {
  /* display: inline-block; */
  vertical-align: middle;
  float: left;
  margin-right: 5px;
  margin-top: -2px;
}

.rightFooter {
  width: 100%;
  padding: 28px 0px 35px 0px;
  list-style: none;
  text-align: left;
}

.notice-title {
  padding-left: 20px;
  font-weight: bold;
  line-height: 40px;
  border-bottom: 2px solid #f3f3f3;
  margin-top: 0;
  text-align: left;
}

.notice-title img {
  margin-right: 10px;
}

.up_box /deep/ .el-form-item__content {
  display: flex;
  justify-content: center;
  border: 1px dashed #d9d9d9;
  position: relative;
}

.up_box /deep/ .el-form-item__content .upload-demo {
  width: 100%;
}

.up_box /deep/ .el-form-item__content .upload-demo .el-upload {
  padding: 15px;
  width: 100%;
  flex: 5;
}

.up_box /deep/ .el-form-item__content .upload-demo .el-upload-dragger {
  border: none;
  width: 97%;
  height: 100%;
}

.del {
  flex: 1;
  position: absolute;
  right: 20px;
  line-height: 280px;
}

.rightFooter li {
  width: 96%;
  position: relative;
  color: #878787;
  padding-left: 100px;
  line-height: 22px;
  font-size: 15px;
  margin-bottom: 10px;
}

.rightFooter span {
  display: inline-block;
  height: 22px;
  text-align: center;
  line-height: 22px;
  color: #fff;
  font-size: 14px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 0px 13px;
  position: absolute;
  left: 0;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-default {
  background-color: #777;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #286090;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c;
}

.download {
  background: #0142a8;
  height: 40px;
  position: relative;
  text-align: center;
  color: #ffffff;
  margin-bottom: 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.download span {
  line-height: 40px;
  color: #ffffff;
}

.download a {
  text-decoration: none;
}

.el-select {
  width: 100% !important;
}

.tips {
  display: inline-block;
  position: absolute;
  width: 60px;
  font-size: 18px;
  font-weight: 700;
  color: red;
}

.up_tips {
  right: -60px;
  top: 120px;
}

.dispkat {
  list-style: none;
  width: auto;
  display: flex;
  padding: 0;
  margin: 0;
}

.dispkat li {
  width: 200px;
  height: 105px;
  cursor: pointer;
  margin-right: 30px;
  position: relative;
  border-radius: 2px;
}

.tabs {
  width: 100%;
  overflow: auto;
  position: relative;
}

.toLeft,
.toRight {
  position: absolute;
  top: 0;
  color: #f4f4f4;
  cursor: pointer;
  line-height: 97px;
  user-select: none;
}

.toLeft {
  left: 0;
  font-size: 20px;
  display: inline-block;
  height: 97px;
  z-index: 888;
  width: 20px;
  background: rgb(0, 0, 0, .5);
}

.toRight {
  right: 0;
  font-size: 20px;
  display: inline-block;
  height: 97px;
  width: 20px;
  background: rgb(0, 0, 0, .5);
}

.select {
  display: none;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  line-height: 65px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.select img {
  margin-top: 5px;
}

.select.active {
  display: block;
}

.shadow {
  border-radius: 3px;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 210px;
  height: 97px;
  background-color: rgb(0, 0, 0, .5);
}

.shadow.active {
  display: block;
}

.info {
  display: flex;
  box-sizing: border-box;
  padding: 0 20px;
  justify-content: space-between;
  margin-top: 30px;
  font-size: 14px;
  color: #1272d7;
  background-color: #f0f7ff;
  height: 48px;
  line-height: 48px;
}

.info span {
  font-size: 15px;
  font-weight: bold;
  margin-right: 4px;
}

.info img {
  vertical-align: -2px;
}

.left-box-a img {
  margin-right: 10px;
  vertical-align: -3px;
}

.fw {
  font-size: 20px;
  font-weight: normal;
}

.fw img {
  vertical-align: -4px;
}

.excuse {
  width: 226px;
  text-align: center;
  color: #6f6f6f;
  background: #f5f5f5;
  border: 1px solid #d6d6d6;
  font-size: 15px;
  line-height: 40px;
  margin-top: 20px;
  cursor: pointer;
  height: 42px;
  line-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.excuse .img {
  width: 24px;
  height: 24px;
}

.disInfo {
  margin-top: -10px;
  font-size: 14px;
}

.disInfo .title {
  line-height: 1.6;
  margin-bottom: 0;
  color: #0c368a;
  padding-top: 22px;
}

.disInfo .content {
  color: #757575;
  font-size: 12px;
  font-family: SimSun;
  line-height: 24px;
  margin: 0;
}

.upload {
  text-align: left;
}

.showNum {
  position: absolute;
  right: 1px;
  height: 38px;
  border-radius: 4px;
  top: 1px;
  padding: 0 20px;
  background: #fff;
}

.footer {
  width: 100%;
  height: 160px;
  background: #2d2e32;
}

.footer .info {
  width: 100%;
  text-align: center;
}

.footer .info a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  margin: 0 18px;
}

.editVer {
  height: auto;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.editVer div {
  line-height: 30px;
}

.uphandle {
  color: #1366e2;
  ext-decoration: none;
  font-size: 14px;
}

.uptips {
  font-size: 12px;
  padding-top: 6px;
  width: 100%;
  text-align: center;
}

.upred {
  font-size: 12px;
  padding-top: 6px;
  width: 100%;
  text-align: center;
  color: red;
}

.up_box p {
  padding: 0;
  margin: 0;
  height: 24px;
}

.modal {
  position: absolute;
  width: 600px;
  padding: 45px 52px 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  z-index: 999;
  right: 270px;
  top: -18px;
  box-shadow: 0 0 10px #ccc;
}

.modal .top {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
}

.modal .top p {
  text-align: left;
  margin: 6px 0;
}

.modal ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0;
}

.modal ul li {
  width: 45%;
  font-size: 14px;
  text-align: left;
  line-height: 26px;
  color: #282828;
}

.modal img {
  width: 210px;
  height: 97px;
}

.ercode {
  padding: 4px 14px 50px;
  position: absolute;
  width: 330px;
  left: -400px;
  top: -60px;
  background-color: #fff;
  box-shadow: 0 0 10px #ccc;
}

.check-area {
  height: 212px;
  overflow-y: scroll;
}

.footer p {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #999;
}
</style>