<template>
  <div id="kami">
    <div class="block">
      <el-input v-model="searchKa" style="width: 200px" placeholder="请输入卡密"></el-input>
      <el-button type="primary" @click="search">搜索</el-button>
    </div>
    <!--    <a href="http://127.0.0.1:8081/downloadFailedUsingJson/" ></a>-->
    <el-button type="text" @click="dialogFormVisible = true">新增卡密</el-button>
    <el-button type="text" @click="dialogExcel = true">导出Excel</el-button>
    <el-button type="text" @click="dialogCardGroup =true">分组管理</el-button>

    <el-dialog title="分组管理" :visible.sync="dialogCardGroup">
      <el-button type="primary" @click="innerVisible = true">新增分组</el-button>
      <el-dialog
          width="30%"
          title="新增分组"
          :visible.sync="innerVisible"
          append-to-body>
        <label>分组名:</label>
        <el-input v-model.number="cardGroupName"></el-input>
        <el-button @click="addCardGourp">确定</el-button>
        <el-button @click="innerVisible=false">取消</el-button>
      </el-dialog>
      <el-table :data="cardGroupList">
        <el-table-column property="id" label="编号"></el-table-column>
        <el-table-column property="name" label="分组名"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="danger"
                @click="handleDeleteGrop(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="导出Excel" :visible.sync="dialogExcel">
      <el-form :model="excelNum" ref="card" :rules="rules">
        <el-form-item label="导出分组" :label-width="formLabelWidth" prop="num">
          <el-select v-model="excelNum.num" placeholder="请选择卡密分组">
            <el-option v-for="carGroup in cardGroupList" :key="carGroup.id" :label="carGroup.name" :value="carGroup.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExcel = false">取 消</el-button>
        <el-button type="primary" @click="downExcel('card')">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="新增卡密" :visible.sync="dialogFormVisible">
      <el-form :model="card" ref="card" :rules="rules">
        <el-form-item label="新增数量" :label-width="formLabelWidth" prop="num">
          <el-input v-model.number="card.num" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="价值" :label-width="formLabelWidth" prop="value">
          <el-input v-model.number="card.value" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="分组" :label-width="formLabelWidth">
          <el-select v-model="card.cardGroupId" placeholder="请选择卡密分组">
            <el-option v-for="carGroup in cardGroupList" :key="carGroup.id" :label="carGroup.name" :value="carGroup.id">

            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="newCar('card')">确 定</el-button>
      </div>
    </el-dialog>

    <el-table
        ref="multipleTable"
        :data="pageCard.dataList"
        style="width: 100%">
      <el-table-column
          label="卡密">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.carMy }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="生成时间"
          width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.createDate }}</span>
        </template>
      </el-table-column>
      <el-table-column
          width="80"
          label="价值">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.value }}</span>
        </template>
      </el-table-column>
      <el-table-column
          width="80"
          label="状态">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.status == 1 ? "正常" : "已使用" }}</span>
        </template>
      </el-table-column>
      <el-table-column
          width="80"
          label="分组编号">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.cardGroupId }}</span>
        </template>
      </el-table-column>
      <el-table-column
          width="80"
          label="分组名">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.cardGroupName || '暂无分组名' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!--          <el-button-->
          <!--              size="mini"-->
          <!--              @click="handleEdit(scope.$index, scope.row)">编辑-->
          <!--          </el-button>-->
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        layout="prev, pager, next"
        :total="pageCard.total"
        :page-size="pageData.rows"
        :current-page="pageData.current"
        @current-change="loadAllFunctions">
    </el-pagination>

  </div>
</template>

<script>
export default {
  name: "kami",
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('数量不能为空'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (value < 0) {
            callback(new Error('必须大于0'));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var checkValue = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('价值不能为空'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (value < 0) {
            callback(new Error('必须大于0'));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    return {
      searchKa:"",
      card: {num: 1, value: 1, cardGroupId: 20},
      pageCard: [],
      pageData: {current: 1, rows: 10},
      dialogFormVisible: false,
      dialogExcel: false,

      cardGroupName: '',
      cardGroupList: [],
      dialogCardGroup: false,
      innerVisible: false,

      excelNum: {num: 0},

      formLabelWidth: '120px',

      rules: {
        num: [
          {validator: checkAge, trigger: 'blur'}
        ],
        value: [
          {validator: checkValue, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    search(){
      if (this.searchKa===''){
        this.pageFindCard();
        return;
      }
      this.axios.post('/card/search/'+this.searchKa, this.pageData).then(res => {
        this.pageCard = res.data;
      })
    },
    handleDeleteGrop(index,row){
      this.$confirm('此操作将永久删除该组,并且会删除当前组下面所有卡密 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.get("/cardgroup/delete/" + row.id).then(res => {
          if (res.data == "ok") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.pageFindCard();
            this.findAllCardGroup();
          } else {
            this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addCardGourp() {
      if (this.cardGroupName == '' || this.cardGroupName == null) {
        this.$message.warning('分组名不能为空');
        return;
      }
      this.axios.get('/cardgroup/add/' + this.cardGroupName).then(res => {
        if (res.data == 'ok') {
          this.$message.success('添加成功');
          this.innerVisible = false;
          this.findAllCardGroup();
        } else {
          this.$message.error('添加失败');
        }
      })
    },
    findAllCardGroup() {
      this.axios.get('/cardgroup/findall').then(res => {
        this.cardGroupList = res.data;
      })
    },
    pageFindCard() {
      this.axios.post('/card/page/find', this.pageData).then(res => {
        this.pageCard = res.data;
        console.log(res)
      })
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {

      this.$confirm('此操作将永久删除该卡密, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.get("/card/remove/" + row.id).then(res => {
          if (res.data == "ok") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.pageFindCard();
          } else {
            this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    loadAllFunctions(a) {
      this.pageData.current = a;
      this.pageFindCard();
    },
    newCar(ruleForm) {
      if (this.card.cardGroupId==0||this.card.cardGroupId==null){
        this.$message.warning('请选择分组');
        return;
      }
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.axios.post('/card/add', this.card).then(res => {
            if (res.data == 'ok') {
              alert("添加成功");
              this.pageFindCard();
              this.dialogFormVisible = false;
            } else {
              alert("添加失败");
              this.dialogFormVisible = false;
            }
          })
        } else {
          return false;
        }
      });
    },
    downExcel(ruleForm) {
      if (this.excelNum.num==0||this.excelNum.num==null){
        this.$message.warning('请选择分组');
        return;
      }
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.axios.get('/downloadFailedUsingJson/' + this.excelNum.num).then(res => {
            this.download(res);
          })
        } else {
          return false;
        }
      });
    },
    download(data) {
      if (!data) {
        return
      }
      let url = window.URL.createObjectURL(new Blob([data]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = '#' + this.excelNum.num
      document.body.appendChild(link)
      link.click()
    }

  },
  mounted() {
    this.pageFindCard();
    this.findAllCardGroup();
  }
}
</script>

<style scoped>

</style>