<template>
  <div>
    <el-container>
      <el-header class="h_r">
        <div class="title" >
          <span class="my-logo">检查</span>
        </div>
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          mode="horizontal"
        >
          <div id="kami" style="display: none">
            <el-submenu index="1">
              <span slot="title">--</span>
              <el-menu-item index="2">
                <span slot="title" v-on:click="handleOpen">khsc</span>
              </el-menu-item>
              <el-menu-item index="2">
                <span slot="title" v-on:click="openPage2">check</span>
              </el-menu-item>
              <el-menu-item index="2">
                <span slot="title" v-on:click="openPage4">spgl</span>
              </el-menu-item>
            </el-submenu>
          </div>
          <el-menu-item index="2" class="custom-menu-item" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
            <i class="el-icon-document-copy"></i> <!-- 更换为更合适的图标 -->
            <span slot="title" v-on:click="openPage5">返回首页</span>
          </el-menu-item>
          <el-menu-item index="2" class="custom-menu-item" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
            <i class="el-icon-search"></i> <!-- 更换为更合适的图标 -->
            <span slot="title" v-on:click="openPage3">查询报告</span>
          </el-menu-item>
        </el-menu>
      </el-header>
      <div class="jumbotron"></div>
      <el-main class="content">
        <router-view></router-view>
      </el-main>
      <div class="footer">
      <div class="info">
        <a href="#"> 首页 </a>
        <a href="#"> 维普报告下载 </a>
        <a href="#"> 真伪验证 </a>
        <a href="#"> 维普查重入口 </a>
      </div>
      <p>Copyright © 2025 版权所有</p>
    </div>
    </el-container>
  </div>

  <!-- <div id="test">
    <header class="el-header" style="height: 60px; background-color: #545c64">
      <div
        style="float: left; font-size: 22px; color: white; line-height: 60px"
      >
        论文检查系统
      </div>
      <div
        style="float: right; font-size: 14px; color: white; line-height: 60px"
      >
        <i class="el-icon-user-solid"> </i>
      </div>
    </header>
    <el-container>
      <el-aside width="300px">
        <el-row class="tac">
          <el-col :span="12" width="10%">
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              background-color="#545c64"
              text-color="#fff"
              active-text-color="#ffd04b"
            >
              <div id="kami" style="display: none">
                <el-submenu index="1">
                  <span slot="title">卡密生成</span>
                </el-submenu>

                <el-menu-item index="2">
                  <i class="el-icon-menu"></i>
                  <span slot="title" v-on:click="openPage2">论文处理</span>
                </el-menu-item>

                <el-menu-item index="2">
                  <i class="el-icon-menu"></i>
                  <span slot="title" v-on:click="openPage4">商品管理</span>
                </el-menu-item>
              </div>
              <el-menu-item index="2">
                <i class="el-icon-menu"></i>
                <span slot="title" v-on:click="openPage1">论文上传</span>
              </el-menu-item>
              <el-menu-item index="2">
                <i class="el-icon-menu"></i>
                <span slot="title" v-on:click="openPage3">报告下载</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-aside>
      <el-main style="width: 100%">
        <router-view style="width: 100%"></router-view>
      </el-main>
    </el-container>
  </div>  -->
</template>

<script>
export default {
  name: "test",
  data() {
    return {};
  },
  methods: {
    handleOpen(key, keyPath) {
      this.$router.push({ path: "/kami36FA64A76908B8489E7A89D5C72857C0" });
    },
    handleClose(key, keyPath) {
      this.$router.push({ path: "/kami36FA64A76908B8489E7A89D5C72857C0" });
    },
    openPage1() {
      this.$router.push({ path: "/upload" });
    },
    openPage5() {
      this.$router.push({ path: "/gocheck?id=4" });
    },
    openPage2() {
      this.$router.push({ path: "/lunwenList2F00B141A80B3489DD3093464D9A424E" });
    },
    openPage3() {
      this.$router.push({ path: "/report" });
    },
    openPage4() {
      this.$router.push({ path: "/commodityEC5CB3E6E19049C477878043D35194EE" });
    },
  },
  mounted() {
    setInterval(() => {
      localStorage.clear();
    }, 1800000);
    // this.judge();
  },
  created() {
    console.log(this.$route.params);
  },
};
</script>

<style scoped>
/* .el-col {
    overflow: hidden;
} */

.el-container {
  height: 100%;
}

/* .el-row {
    width: 100%;
} */

#test a {
  text-decoration: none;
  color: white;
}

.h_r {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 0 16%;
  border-bottom: 1px solid #f4f4f4;
}

.title {
  display: flex;
  align-content: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 60px;
  padding-left: 50px;
  background: url("../../assets/logo1.png") no-repeat scroll 0 0 transparent;
  width: 250px;
  height: 45px;
  background-size: 100%;
  margin: 10px 0 10px 5px;
  text-indent: -9999px;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border: 0;
}

.content {
  padding-left: 16%;
  padding-right: 16%;
}

.jumbotron {
  margin-bottom: 30px;
  color: inherit;
  background-color: #0142a8;
}

.my-logo {
  width: 140px;
  height: 45px;
  display: block;
}


.footer {
  width: 100%;
  height: 160px;
  background: #2d2e32;
}

.footer .info {
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  margin-top: 20px;
}

.footer .info a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  margin: 0 18px;
}

.footer p {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #999;
}

/* 新增样式部分 */
/* 以下是新增的样式，用于进一步美化菜单项 */
.custom-menu-item {
  /* 新增：增加内边距，让菜单项内容更宽松 */
  padding: 0 30px; 
  /* 新增：设置字体大小 */
  font-size: 20px; 
}

/* 新增：鼠标悬停时，改变图标的颜色 */
.custom-menu-item:hover i {
  color: #ff2200;
}

/* 新增：为活动菜单项添加样式 */
.el-menu--horizontal > .el-menu-item.is-active {
  /* 新增：改变活动菜单项的背景颜色 */
  background-color: #ffffff00; 
  /* 新增：改变活动菜单项的文字颜色 */
  color: #0e0d0d; 
}
</style>